import React from 'react';
import { MAX_LIST_SELECTS, PROJECT_ARCHIVED } from './constants';
import PropTypes from 'prop-types';
import AutodeskProjectSelector from './AutodeskProjectSelector';
import AutodeskHubSelector from './AutodeskHubSelector';
import AutodeskCircleConnected from './AutodeskCircleConnected';
import OutbuildStageSelector from './OutbuildStageSelector';
import OutbuildProjectCheckbox from './OutbuildProjectCheckbox';
import AutodeskScheduleSelector from './AutodeskScheduleSelector';

/**
 * TableContent component for displaying and managing Autodesk project integrations.
 * @param {Object} props
 * @param {Function} props.t - Translation function
 * @param {Array} props.autodeskHubs - List of available Autodesk hubs
 * @param {Array} props.listCheckSelected - List of selected projects
 * @param {Object} props.assignedAutodeskProjectSchedules - Currently assigned Autodesk projects
 * @param {Array} props.sortedProjects - List of sorted projects
 * @param {Boolean} props.showFormErrors - Whether to show form errors
 * @param {Function} props.getSectorLabel - Function to get sector label
 * @param {Function} props.handleSectorSelection - Handler for sector selection
 * @param {Function} props.handleHubSelection - Handler for hub selection
 * @param {Function} props.handleAutodeskProjectSelection - Handler for Autodesk project selection
 * @param {Function} props.handleClickCheck - Handler for project selection
 */

const TableContent = ({
  t,
  autodeskHubs,
  listCheckSelected,
  assignedAutodeskProjectSchedules,
  sortedProjects,
  showFormErrors,
  getSectorLabel,
  handleSectorSelection,
  handleHubSelection,
  handleAutodeskProjectSelection,
  handleAutodeskProjectScheduleSelection,
  handleClickCheck,
  isLoadingListCheckSelected,
  isLoadingHubs
}) => {
  const renderProject = (project) => {
    const isProjectChecked = listCheckSelected?.some(
      (check) => check.proplanner_project === project?.id
    );
    const projectArchived = project.stage === PROJECT_ARCHIVED;
    const projectCheck = listCheckSelected?.find(
      (check) => check.proplanner_project === project?.id
    );
    const isChecked = projectCheck !== undefined;
    const isConnected =
      isChecked &&
      listCheckSelected?.find(
        (checked) => checked.proplanner_project === project?.id && checked.id
      );

    const disableHubSelector = listCheckSelected?.find(
      (checked) =>
        checked.proplanner_project == project?.id && !checked.project_stage
    );

    const disableAutodeskProjectSelector = listCheckSelected?.find(
      (checked) =>
        checked.proplanner_project == project?.id && !checked.autodesk_hub
    );

    const disableAutodeskScheduleSelector = listCheckSelected?.find(
      (checked) =>
        checked.proplanner_project == project?.id && !checked.autodesk_project
    );

    const selectorHubLabel = listCheckSelected?.find(
      (checked) => checked.proplanner_project == project?.id
    )?.autodesk_hub?.attributes?.name;

    const selectorAutodeskProjectLabel = listCheckSelected?.find(
      (checked) => checked.proplanner_project == project?.id
    )?.autodesk_project?.attributes?.name;

    const selectorAutodeskScheduleLabel = listCheckSelected?.find(
      (checked) => checked.proplanner_project == project?.id
    )?.autodesk_schedule?.attributes?.name;

    const autodeskProjects =
      listCheckSelected?.find(
        (checked) => checked.proplanner_project == project?.id
      )?.autodesk_hub?.projects || [];

    const autodeskProjectSchedules =
      listCheckSelected?.find(
        (checked) => checked.proplanner_project == project?.id
      )?.autodesk_project?.schedules || [];

    return (
      <div key={project?.id} className="checkbox__detail_autodesk">
        <OutbuildProjectCheckbox
          isProjectChecked={isProjectChecked}
          project={project}
          projectArchived={projectArchived}
          t={t}
          handleClickCheck={handleClickCheck}
          isLoadingListCheckSelected={isLoadingListCheckSelected}
          isLoadingHubs={isLoadingHubs}
        />
        <OutbuildStageSelector
          isProjectChecked={isProjectChecked}
          listCheckSelected={listCheckSelected}
          project={project}
          showFormErrors={showFormErrors}
          getSectorLabel={getSectorLabel}
          handleSectorSelection={handleSectorSelection}
        />
        <AutodeskCircleConnected
          t={t}
          isConnected={isConnected}
          isChecked={isChecked}
        />
        <AutodeskHubSelector
          disableHubSelector={disableHubSelector}
          isProjectChecked={isProjectChecked}
          listCheckSelected={listCheckSelected}
          project={project}
          showFormErrors={showFormErrors}
          t={t}
          selectorHubLabel={selectorHubLabel}
          autodeskHubs={autodeskHubs}
          handleHubSelection={handleHubSelection}
        />
        <AutodeskProjectSelector
          disableAutodeskProjectSelector={disableAutodeskProjectSelector}
          isProjectChecked={isProjectChecked}
          listCheckSelected={listCheckSelected}
          project={project}
          showFormErrors={showFormErrors}
          t={t}
          selectorAutodeskProjectLabel={selectorAutodeskProjectLabel}
          autodeskProjects={autodeskProjects}
          handleAutodeskProjectSelection={handleAutodeskProjectSelection}
        />
        <AutodeskScheduleSelector
          disableAutodeskScheduleSelector={disableAutodeskScheduleSelector}
          isProjectChecked={isProjectChecked}
          listCheckSelected={listCheckSelected}
          project={project}
          showFormErrors={showFormErrors}
          t={t}
          selectorAutodeskScheduleLabel={selectorAutodeskScheduleLabel}
          autodeskProjectSchedules={autodeskProjectSchedules}
          handleAutodeskProjectScheduleSelection={
            handleAutodeskProjectScheduleSelection
          }
          assignedAutodeskProjectSchedules={assignedAutodeskProjectSchedules}
        />
      </div>
    );
  };

  return (
    <div
      className="list-projects__checkbox"
      style={{
        overflowY:
          sortedProjects.length > MAX_LIST_SELECTS ? 'scroll' : 'inherit',
        paddingRight: sortedProjects.length > MAX_LIST_SELECTS ? 10 : 0
      }}>
      {sortedProjects?.map((project) => renderProject(project))}
    </div>
  );
};

TableContent.propTypes = {
  t: PropTypes.func.isRequired,
  autodeskHubs: PropTypes.array.isRequired,
  listCheckSelected: PropTypes.array,
  assignedAutodeskProjectSchedules: PropTypes.object.isRequired,
  sortedProjects: PropTypes.array.isRequired,
  showFormErrors: PropTypes.bool.isRequired,
  getSectorLabel: PropTypes.func.isRequired,
  handleSectorSelection: PropTypes.func.isRequired,
  handleHubSelection: PropTypes.func.isRequired,
  handleAutodeskProjectSelection: PropTypes.func.isRequired,
  handleAutodeskProjectScheduleSelection: PropTypes.func.isRequired,
  handleClickCheck: PropTypes.func.isRequired,
  isLoadingListCheckSelected: PropTypes.bool.isRequired,
  isLoadingHubs: PropTypes.bool.isRequired
};

export default TableContent;
