import { useQuery } from 'react-query';
import { useMemo } from 'react';
import {
  AUTH_URL,
  Authorization,
  autodeskIntegrationService
} from '../../../../../services/autodeskintegration.service';
import * as Sentry from '@sentry/react';
import { base } from '../../../../../services/base';
import { trackingEvent } from '../../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../../analytics/constants';
import { getSignedUser } from '../../../../../utils/userUtils';

export const useAutodeskIntegration = () => {
  const company = useMemo(() => {
    try {
      const companyData = sessionStorage.getItem('company');
      if (!companyData) return {};
      const parsed = JSON.parse(companyData);
      if (!parsed.id) throw new Error('Invalid company data');
      return parsed;
    } catch (error) {
      Sentry.captureMessage(
        'Invalid company data in sessionStorage',
        'warning'
      );
      return {};
    }
  }, []);

  const loggedUser = getSignedUser();
  const handleLogin = () => {
    trackingEvent(
      'start_autodesk_integration_connection',
      { organization_id: company?.id, organization_name: company?.name },
      AMPLITUDE_SERVICE
    );
    window.location.href = AUTH_URL;
  };
  const eventData = {
    event_source: 'Integration',
    organization_name: company?.name,
    organization_id: company?.id,
    user_who_tried_to_connect: loggedUser?.email
  };

  const {
    data: accessToken,
    error,
    isLoading
  } = useQuery(
    ['autodeskIntegration', company.id],
    async () => {
      const companyAutodeskIntegration =
        await autodeskIntegrationService.getByCompany(company.id);

      if (companyAutodeskIntegration) {
        return companyAutodeskIntegration;
      }

      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      if (code) {
        const data = await autodeskIntegrationService.exchangeToken(code);

        const newIntegrationObject = {
          token: data.access_token,
          refreshtoken: data.refresh_token,
          authentication: Authorization,
          clientsecret: base.autodesk.secret,
          clientkey: base.autodesk.client,
          companyId: company.id
        };

        const newIntegration =
          await autodeskIntegrationService.create(newIntegrationObject);

        trackingEvent(
          'Autodesk_Outbuild_connection_attempt',
          { ...eventData, result: newIntegration ? 'Success' : 'Fail' },
          AMPLITUDE_SERVICE
        );

        window.history.replaceState(
          {},
          document.title,
          base.autodesk.redirectUri
        );
        return { ...newIntegration, showNotConnectedModal: true };
      }

      return null;
    },
    {
      retry: false,
      onError: (error) => {
        trackingEvent(
          'Autodesk_Outbuild_connection_attempt',
          { ...eventData, result: 'Error', error: error },
          AMPLITUDE_SERVICE
        );
        Sentry.captureMessage('Cannot login with Autodesk', 'warning');
      }
    }
  );

  return {
    accessToken,
    handleLogin,
    isLoading,
    error
  };
};
