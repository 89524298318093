import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CardsGeneral from './CardsGeneral/CardsGeneral';
import './CardSettingsPlugin.scss';

const CardSettingPlugin = ({ t }) => (
  <div className="settings-plugins">
    <h1 className="settings-plugins__title">
      {t('settings.plugins_menu.title')}
    </h1>
    <div className="settings-plugins__cards">
      <CardsGeneral />
    </div>
  </div>
);

CardSettingPlugin.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(CardSettingPlugin);
