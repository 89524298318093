import React from 'react';
import {
  ACTIVE,
  WHITE_COLOR,
  DISABLED,
  PLACEMENT_TOP,
  ERROR,
  ERROR_COLOR,
  getTextLabel
} from './constants';
import { ArrowDownIcon } from '../../../../../icons';
import TooltipSystem from '../../../../DesignSystem/TooltipSystem';
import { Dropdown, Menu } from 'antd';
import { dynamicSort } from '../../../../../utils';
import PropTypes from 'prop-types';

const AutodeskHubSelector = ({
  disableHubSelector,
  isProjectChecked,
  listCheckSelected,
  project,
  showFormErrors,
  t,
  selectorHubLabel,
  autodeskHubs,
  handleHubSelection
}) => (
  <div className="detail__select-project">
    <Dropdown
      overlayClassName="select-project__overlay-dropdown"
      disabled={disableHubSelector || !isProjectChecked}
      overlay={
        <Menu>
          {autodeskHubs?.sort(dynamicSort('name'))?.map((autodeskHub) => (
            <Menu.Item
              key={autodeskHub.id}
              className={
                listCheckSelected.some(
                  (check) =>
                    check.proplanner_project === project?.id &&
                    check?.autodesk_hub?.id === autodeskHub.id
                )
                  ? ACTIVE
                  : ''
              }
              onClick={() => handleHubSelection(project?.id, autodeskHub)}>
              {TooltipSystem({
                children: getTextLabel(autodeskHub.attributes.name),
                placement: PLACEMENT_TOP,
                title: autodeskHub.name
              })}
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={['click']}>
      <div
        className={`select-project__dropdown ${
          disableHubSelector || !isProjectChecked ? DISABLED : ''
        } ${
          showFormErrors &&
          listCheckSelected.some(
            (check) =>
              check.proplanner_project === project?.id && !check.autodesk_hub
          )
            ? ERROR
            : ''
        }`}>
        <span>
          {disableHubSelector || !isProjectChecked ? (
            <div>&nbsp;</div>
          ) : (
            getTextLabel(
              selectorHubLabel ||
                t('autodesk.manage_connections_modal.select_hub')
            )
          )}
        </span>
        <ArrowDownIcon
          color={
            showFormErrors &&
            listCheckSelected.some(
              (check) =>
                check.proplanner_project === project?.id && !check.autodesk_hub
            )
              ? ERROR_COLOR
              : WHITE_COLOR
          }
        />
      </div>
    </Dropdown>
  </div>
);

AutodeskHubSelector.propTypes = {
  disableHubSelector: PropTypes.bool.isRequired,
  isProjectChecked: PropTypes.bool.isRequired,
  listCheckSelected: PropTypes.arrayOf(
    PropTypes.shape({
      proplanner_project: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      project_stage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      autodesk_hub: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      })
    })
  ).isRequired,
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  showFormErrors: PropTypes.bool,
  t: PropTypes.func.isRequired,
  selectorHubLabel: PropTypes.string,
  autodeskHubs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  handleHubSelection: PropTypes.func.isRequired
};

export default AutodeskHubSelector;
