import React from 'react';
import PropTypes from 'prop-types';
import TooltipSystem from '../../../../DesignSystem/TooltipSystem';
import { PLACEMENT_TOP } from './constants';

const AutodeskCircleConnected = ({ t, isConnected, isChecked }) => (
  <div className="detail__connection">
    {(() => {
      if (isChecked) {
        return TooltipSystem({
          children: (
            <div
              className={`icon-connect ${isConnected ? 'connected' : 'disconnected'}`}
            />
          ),
          placement: PLACEMENT_TOP,
          title: t(
            isConnected
              ? 'autodesk.manage_connections_modal.connected'
              : 'autodesk.manage_connections_modal.disconnected'
          ),
          theme: 'dark'
        });
      }
      return null;
    })()}
  </div>
);

AutodeskCircleConnected.propTypes = {
  t: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired
};

export default AutodeskCircleConnected;
