import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import ModalSystem from '../../../../DesignSystem/ModalSystem';
import ButtonSystem from '../../../../DesignSystem/ButtonSystem';
import { InformationCircleIcon } from '../../../../../icons';
import { Colors } from '../../../../../constants/colors.constants';
import './AutodeskNotConnectedModal.scss';
import { openNotification } from '../../../../../utils';

const STEP_BY_STEP_GUIDE_URL =
  'https://knowledgebase.outbuild.com/en/knowledge/how-to-connect-your-outbuild-project-to-autodesk';
const AUTODESK_CC_URL = 'https://acc.autodesk.com/';

const AutodeskNotConnectedModal = ({ t, showModal, setShowModal }) => {
  const handleCloseModal = () => {
    setShowModal(false);
    openNotification(
      {
        title: t('autodesk.not_connected_modal.connection_cancelled_title'),
        description: t('autodesk.not_connected_modal.connection_cancelled'),
        type: 'warning',
        key: 'autodeskConnectedModalCancelled'
      },
      5
    );
  };

  const contentModal = () => (
    <div className="autodesk-not-connected-modal">
      <InformationCircleIcon
        className="autodesk-not-connected-modal__icon"
        color={Colors.LIGHT_BLUE}
      />
      <div className="autodesk-not-connected-modal__message">
        <span>{t('autodesk.not_connected_modal.part_one')}</span>
        <span>{t('autodesk.not_connected_modal.part_two')}</span>
        <span>
          <Trans
            i18nKey="autodesk.not_connected_modal.part_three"
            components={{
              guideLink: (
                <a
                  className="autodesk-not-connected-modal__link"
                  href={STEP_BY_STEP_GUIDE_URL}
                  target="_blank"
                  rel="noreferrer"
                />
              )
            }}
          />
        </span>
      </div>

      <div className="autodesk-not-connected-modal__footer">
        <ButtonSystem type="secondary" onClick={handleCloseModal}>
          {t('cancel')}
        </ButtonSystem>

        <a
          className="autodesk-not-connected-modal__footer-link"
          href={AUTODESK_CC_URL}
          target="_blank"
          rel="noreferrer">
          {t('autodesk.not_connected_modal.go_to_autodesk')}
        </a>
      </div>
    </div>
  );

  return ModalSystem({
    theme: 'dark',
    width: 580,
    showTitle: false,
    showIconTitle: true,
    visible: showModal,
    setVisible: setShowModal,
    centered: true,
    children: contentModal(),
    onCancelFunction: handleCloseModal
  });
};

AutodeskNotConnectedModal.propTypes = {
  t: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

export default AutodeskNotConnectedModal;
