import React, { lazy, Suspense, useState } from 'react';
import { Animated } from 'react-animated-css';
import ProcoreImg from '../../../../assets/img/procore-variant2.png';
import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useProcoreData } from '../../../../hooks/useProcoreData';
import DropdownSystem from '../../../DesignSystem/DropdownSystem/DropdownSystem';
import ButtonSystem from '../../../DesignSystem/ButtonSystem/ButtonSystem';
import { MoreFilledIcon } from '../../../../icons';
import { openNotification } from '../../../../utils';
import { useProcoreCompaniesImport } from '../Authorizers/Procore/ProcoreCompaniesImportModal/useProcoreCompaniesImport';
import { Colors } from '../../../../constants/colors.constants';
const AddProject = lazy(() => import('../Authorizers/Procore/AddProject'));
const PermissionModal = lazy(
  () => import('../Authorizers/Procore/PermissionModal')
);
const DirectoryModal = lazy(
  () => import('../Authorizers/Procore/importDirectoryModal/DirectoryModal')
);
const ViewInfoModal = lazy(
  () => import('../Authorizers/Procore/ViewInfoModal')
);

const ProcoreCompaniesImportModal = lazy(
  () => import('../Authorizers/Procore/ProcoreCompaniesImportModal')
);

const MatchRolesModal = lazy(
  () => import('../Authorizers/Procore/MatchRolesModal')
);

const ImportUsersModal = lazy(
  () => import('../Authorizers/Procore/importUsersModal')
);

function ItemCard({ plugin }) {
  const { t } = useTranslation();
  const {
    getAgaveLinkToken,
    addProjectModal,
    setShowModalAddProject,
    haveProcore,
    showModalAddProject,
    companies,
    showModalPermissions,
    setShowModalPermissions,
    userEmail,
    importDirectoryModal,
    showDirectoryModal,
    setShowDirectoryModal,
    openImportUsersModal,
    selectedProcoreProjectId,
    setSelectedProcoreProjectId,
    procoreProjectConnected,
    setProcoreProjectConnected,
    procoreCompanyProjects,
    externalProcoreProjects,
    updateProcoreProjects,
    showModalViewInfo,
    setShowModalViewInfo,
    updateProcoreProjectImportStatus
  } = useProcoreData(openNotification, t);

  const {
    dataSource,
    isError,
    isSuccess,
    isFetching,
    isLoading,
    handleOpenModalImportCompanies,
    showProcoreCompaniesImportModal,
    handleCloseModalImportCompanies,
    procoreProjectSelected,
    selectedCompanies,
    handleOnSelectItem,
    handleManageMultipleCompanyAssignments,
    handleChangeSearch,
    searchInput,
    isNoMatchingRecords,
    handleTrackingEvent,
    isCreating,
    createError,
    handleConfirmImport,
    isDisabledConfirmImport,
    colorSelected,
    openPickerId,
    handlePickerToggle,
    handleColorChange,
    pickerRef
  } = useProcoreCompaniesImport();

  const [showModalViewRoleMapping, setShowModalViewRoleMapping] =
    useState(false);
  const [showImportUsersModal, setShowImportUsersModal] = useState(false);
  const AgaveActivationButton = (
    <ButtonSystem onClick={getAgaveLinkToken}>
      {t('procore.connect')}
    </ButtonSystem>
  );

  const ProcoreActivationButton = (
    <ButtonSystem onClick={addProjectModal}>
      {t('procore.add_procore')}
    </ButtonSystem>
  );

  const renderCardContent = () => {
    if (haveProcore === null) {
      return (
        <Spin
          className="loader-spinner-item-card"
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      );
    }

    if (haveProcore) {
      return (
        <>
          {ProcoreActivationButton}
          <div className="settings-plugins__card-connected">
            <CheckCircleTwoTone
              className="spacer"
              twoToneColor={Colors.LIGHT_MINT}
            />
            {t('procore.connected')}
          </div>
        </>
      );
    }

    return AgaveActivationButton;
  };

  return (
    <>
      <Animated animationIn="fadeIn" animationInDuration={1000}>
        <Card
          className="settings-plugins__card"
          cover={
            <img className="settings-plugins__card-cover" src={ProcoreImg} />
          }
          title={plugin.name}
          extra={
            haveProcore &&
            DropdownSystem({
              theme: 'light',
              trigger: ['click'],
              items: [
                {
                  key: '2',
                  label: t('procore.options.extra_options.view_info'),
                  onClick: () => setShowModalViewInfo(true),
                  disabled: false,
                  isDivider: false,
                  active: false,
                  subitems: []
                },
                {
                  key: '1',
                  label: t('procore.options.extra_options.directory_import'),
                  onClick: () => importDirectoryModal(),
                  disabled: false,
                  isDivider: false,
                  active: false,
                  subitems: []
                },
                {
                  key: '3',
                  label: t('procore.options.extra_options.role_match'),
                  onClick: () => setShowModalViewRoleMapping(true),
                  disabled: false,
                  isDivider: false,
                  active: false,
                  subitems: []
                }
              ],
              icon: <MoreFilledIcon />
            })
          }>
          {renderCardContent()}
        </Card>
      </Animated>
      <Suspense fallback={<div></div>}>
        <AddProject
          t={t}
          show={showModalAddProject}
          setShow={setShowModalAddProject}
          companies={companies}
          setProcoreProjectConnected={setProcoreProjectConnected}
          procoreCompanyProjects={procoreCompanyProjects}
          externalProcoreProjects={externalProcoreProjects}
          updateProcoreProjects={updateProcoreProjects}
        />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <PermissionModal
          visible={showModalPermissions}
          setVisible={setShowModalPermissions}
          onCancel={() => setShowModalPermissions(false)}
          userEmail={userEmail}
        />
      </Suspense>

      <Suspense fallback={<div></div>}>
        <DirectoryModal
          t={t}
          show={showDirectoryModal}
          setShow={setShowDirectoryModal}
          companies={companies}
          onOpenImportUsersModal={openImportUsersModal}
          procoreProjectConnected={procoreProjectConnected}
          procoreCompanyProjects={procoreCompanyProjects}
          externalProcoreProjects={externalProcoreProjects}
          updateProcoreProjectImportStatus={updateProcoreProjectImportStatus}
          setProcoreProjectConnected={setProcoreProjectConnected}
          handleOpenModalImportCompanies={handleOpenModalImportCompanies}
          setShowImportUsersModal={setShowImportUsersModal}
        />
      </Suspense>

      <Suspense fallback={<div />}>
        <ViewInfoModal
          t={t}
          visible={showModalViewInfo}
          haveProcore={haveProcore}
          setVisible={setShowModalViewInfo}
        />
      </Suspense>

      <Suspense fallback={<div />}>
        <ProcoreCompaniesImportModal
          dataSource={dataSource}
          isError={isError}
          isSuccess={isSuccess}
          isFetching={isFetching}
          isLoading={isLoading}
          visible={showProcoreCompaniesImportModal}
          handleCloseModalImportCompanies={handleCloseModalImportCompanies}
          procoreProject={procoreProjectSelected}
          selectedCompanies={selectedCompanies}
          handleOnSelectItem={handleOnSelectItem}
          handleManageMultipleCompanyAssignments={
            handleManageMultipleCompanyAssignments
          }
          handleChangeSearch={handleChangeSearch}
          searchInput={searchInput}
          isNoMatchingRecords={isNoMatchingRecords}
          handleTrackingEvent={handleTrackingEvent}
          isCreating={isCreating}
          createError={createError}
          handleConfirmImport={handleConfirmImport}
          isDisabledConfirmImport={isDisabledConfirmImport}
          openPickerId={openPickerId}
          handlePickerToggle={handlePickerToggle}
          handleColorChange={handleColorChange}
          colorSelected={colorSelected}
          pickerRef={pickerRef}
        />
      </Suspense>

      <Suspense fallback={<div />}>
        <MatchRolesModal
          visible={showModalViewRoleMapping}
          setVisible={setShowModalViewRoleMapping}
          procoreCompanies={companies}
        />
      </Suspense>

      <Suspense fallback={<div />}>
        <ImportUsersModal
          visible={showImportUsersModal}
          setVisible={setShowImportUsersModal}
          procoreProjectId={selectedProcoreProjectId}
          setProcoreProjectId={setSelectedProcoreProjectId}
          haveProcore={haveProcore}
          procoreCompanies={companies}
        />
      </Suspense>
    </>
  );
}
export default ItemCard;
