import { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { autodeskIntegrationService } from '../../../../../services/autodeskintegration.service';
import { openNotification } from '../../../../../utils';
import * as Sentry from '@sentry/react';

const RETRY_TIMEOUT_MS = 3000;

export const useAutodeskHubs = ({ company, accessToken, retry }) => {
  const { t } = useTranslation();
  const [isRetryingHubs, setIsRetryingHubs] = useState(false);
  const [notifyOnSuccess, setNotifyOnSuccess] = useState(false);
  const memoizedQueryKey = useMemo(
    () => ['autodeskHubsAndProjects', company?.id],
    [company?.id]
  );

  const queryEnabled = useMemo(
    () => Boolean(company?.id && accessToken),
    [company?.id, accessToken]
  );

  const {
    data: autodeskHubs = [],
    isError,
    isLoading: isLoadingHubs,
    refetch
  } = useQuery(
    memoizedQueryKey,
    async () => {
      const response =
        await autodeskIntegrationService.getAutodeskHubsAndProjects(company.id);

      if (!response) {
        throw new Error('Error getting hubs and projects');
      }

      return response;
    },
    {
      retry: false,
      enabled: queryEnabled,
      onError: (error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'getAutodeskHubsAndProjects'
          },
          extra: { response: error.response }
        });
      }
    }
  );

  useEffect(() => {
    let retryTimeout;
    if (isError && retry) {
      setNotifyOnSuccess(true);
      setIsRetryingHubs(true);
      retryTimeout = setTimeout(async () => {
        await refetch();
        setIsRetryingHubs(false);
      }, RETRY_TIMEOUT_MS);
    }

    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [isError, isLoadingHubs, refetch, retry]);

  useEffect(() => {
    if (!isError && !isRetryingHubs && notifyOnSuccess) {
      openNotification(
        {
          title: t('autodesk.not_connected_modal.connection_completed_title'),
          description: t('autodesk.not_connected_modal.connection_completed'),
          type: 'success',
          key: 'autodeskConnectedModalSuccessfull'
        },
        5
      );
      setNotifyOnSuccess(false);
    }
  }, [isError, isRetryingHubs, notifyOnSuccess]);

  return {
    autodeskHubs,
    hasHubsAccess: !isError && !isRetryingHubs,
    isLoadingHubs,
    isFirstHubsLoading: isLoadingHubs && !isRetryingHubs
  };
};
