import PropTypes from 'prop-types';
import * as React from 'react';
import Colors from '../../../assets/styles/variables.scss';
import {
  CheckCircleIcon,
  DenyCircleIcon,
  InformationCircleOutlineIcon,
  OneCircleIcon,
  ReadOnlyIcon,
  ThreeCircleIcon,
  TwoCircleIcon
} from '../../../icons';
import TooltipSystemV2 from '../../DesignSystem/TooltipSystemV2';
import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';

const { PRIMARY, LIGHT_RED, LIGHT_BLUE, YELLOW, DARK_GRAY } = Colors;

const iconMap = {
  check: { component: CheckCircleIcon, color: PRIMARY },
  'read-only': { component: ReadOnlyIcon, color: LIGHT_BLUE },
  deny: { component: DenyCircleIcon, color: LIGHT_RED },
  one: { component: OneCircleIcon, color: YELLOW },
  two: { component: TwoCircleIcon, color: YELLOW },
  three: { component: ThreeCircleIcon, color: YELLOW }
};

function PermissionsCell({ permissions }) {
  return (
    <div className="permissions-block">
      {permissions.map((permission, index) => {
        const Icon = iconMap[permission]?.component;
        const color = iconMap[permission]?.color;
        return <Icon key={index} color={color} />;
      })}
    </div>
  );
}

PermissionsCell.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export const columns = (t) => [
  {
    title: t('permissions_matrix.columns.module'),
    key: 'module',
    dataIndex: 'module',
    align: 'center',
    width: 155,
    render: (module) => <div className="cell">{module}</div>,
    responsive: ['sm']
  },
  {
    title: () => (
      <div className="super-admin-cell">
        <span>{t('permissions_matrix.columns.super_admin')}</span>
        <TooltipSystemV2
          title={t('permissions_matrix.columns.super_admin_tooltip')}>
          <span>
            <InformationCircleOutlineIcon
              className="tooltip-icon"
              color={DARK_GRAY}
            />
          </span>
        </TooltipSystemV2>
      </div>
    ),
    key: 'superAdmin',
    dataIndex: 'superAdmin',
    align: 'center',
    render: (permissions) => <PermissionsCell permissions={permissions} />,
    responsive: ['sm']
  },
  {
    title: t('permissions_matrix.columns.admin'),
    key: 'admin',
    dataIndex: 'admin',
    align: 'center',
    render: (permissions) => <PermissionsCell permissions={permissions} />,
    responsive: ['sm']
  },
  {
    title: t('permissions_matrix.columns.project_leader'),
    key: 'projectLeader',
    dataIndex: 'projectLeader',
    align: 'center',
    render: (permissions) => <PermissionsCell permissions={permissions} />,
    responsive: ['sm']
  },
  {
    title: t('permissions_matrix.columns.scheduler'),
    key: 'scheduler',
    dataIndex: 'scheduler',
    align: 'center',
    render: (permissions) => <PermissionsCell permissions={permissions} />,
    responsive: ['sm']
  },
  {
    title: t('permissions_matrix.columns.superintendent'),
    key: 'superintendent',
    dataIndex: 'superintendent',
    align: 'center',
    render: (permissions) => <PermissionsCell permissions={permissions} />,
    responsive: ['sm']
  },
  {
    title: t('permissions_matrix.columns.project_partner'),
    key: 'projectPartner',
    dataIndex: 'projectPartner',
    align: 'center',
    render: (permissions) => <PermissionsCell permissions={permissions} />,
    responsive: ['sm']
  },
  {
    title: t('permissions_matrix.columns.foreman'),
    key: 'foreman',
    dataIndex: 'foreman',
    align: 'center',
    render: (permissions) => <PermissionsCell permissions={permissions} />,
    responsive: ['sm']
  },
  {
    title: t('permissions_matrix.columns.owner'),
    key: 'owner',
    dataIndex: 'owner',
    align: 'center',
    render: (permissions) => <PermissionsCell permissions={permissions} />,
    responsive: ['sm']
  },
  {
    title: t('permissions_matrix.columns.read_only'),
    key: 'readOnly',
    dataIndex: 'readOnly',
    align: 'center',
    render: (permissions) => <PermissionsCell permissions={permissions} />,
    responsive: ['sm']
  }
];

export const permissionsData = (t) => [
  {
    module: t('permissions_matrix.modules.schedule'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['check'],
    scheduler: ['check'],
    superintendent: ['read-only'],
    projectPartner: ['read-only'],
    foreman: ['deny'],
    owner: ['read-only'],
    readOnly: ['read-only']
  },
  {
    module: t('permissions_matrix.modules.lookahead'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['check'],
    scheduler: ['check'],
    superintendent: ['check'],
    projectPartner: ['one'],
    foreman: ['read-only'],
    owner: ['deny'],
    readOnly: ['read-only']
  },
  {
    module: t('permissions_matrix.modules.roadblocks'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['check'],
    scheduler: ['check'],
    superintendent: ['check'],
    projectPartner: ['two'],
    foreman: ['read-only'],
    owner: ['deny'],
    readOnly: ['read-only']
  },
  {
    module: t('permissions_matrix.modules.weekly_plan'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['check'],
    scheduler: ['check'],
    superintendent: ['check'],
    projectPartner: ['three'],
    foreman: ['read-only'],
    owner: ['deny'],
    readOnly: ['read-only']
  },
  {
    module: t('permissions_matrix.modules.rfv'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['check'],
    scheduler: ['check'],
    superintendent: ['check'],
    projectPartner: ['three'],
    foreman: ['read-only'],
    owner: ['deny'],
    readOnly: ['read-only']
  },
  {
    module: t('permissions_matrix.modules.analytics'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['check'],
    scheduler: ['check'],
    superintendent: ['check'],
    projectPartner: ['deny'],
    foreman: ['deny'],
    owner: ['deny'],
    readOnly: ['read-only']
  },
  {
    module: t('permissions_matrix.modules.user_settings'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['check'],
    scheduler: ['check'],
    superintendent: ['check'],
    projectPartner: ['check'],
    foreman: ['check'],
    owner: ['check'],
    readOnly: ['check']
  },
  {
    module: t('permissions_matrix.modules.project_settings'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['check'],
    scheduler: ['deny'],
    superintendent: ['deny'],
    projectPartner: ['deny'],
    foreman: ['deny'],
    owner: ['deny'],
    readOnly: ['deny']
  },
  {
    module: t('permissions_matrix.modules.organization_settings'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['deny'],
    scheduler: ['deny'],
    superintendent: ['deny'],
    projectPartner: ['deny'],
    foreman: ['deny'],
    owner: ['deny'],
    readOnly: ['deny']
  },
  {
    module: t('permissions_matrix.modules.integrations'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['deny'],
    scheduler: ['deny'],
    superintendent: ['deny'],
    projectPartner: ['deny'],
    foreman: ['deny'],
    owner: ['deny'],
    readOnly: ['deny']
  },
  {
    module: t('permissions_matrix.modules.ipad_app'),
    superAdmin: ['check'],
    admin: ['check'],
    projectLeader: ['check'],
    scheduler: ['check'],
    superintendent: ['check'],
    projectPartner: ['one', 'two'],
    foreman: ['read-only'],
    owner: ['deny'],
    readOnly: ['read-only']
  }
];

export const trackPermissionsMatrixModal = () => {
  trackingEvent(
    'permissions_matrix_opened',
    {
      ...getBasicAmplitudEventProperties()
    },
    AMPLITUDE_SERVICE
  );
};
