import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ModalSystem from '../../../../DesignSystem/ModalSystem';
import AutodeskIcon from '../../../../../assets/img/icons/icon--autodesk.svg';
import OutbuildIcon from '../../../../../assets/img/icons/icon--outbuild.svg';
import { ALERT_ICON_COLOR, MODAL_THEME, MODAL_WIDTH } from './constants';
import { Trans } from 'react-i18next';
import TableContent from './TableContent';
import { useAutodeskAssignation } from '../hooks/useAutodeskAssignation';
import { LoadingOutlined } from '@ant-design/icons';
import { AlertIcon } from '../../../../../icons';

const AutodeskTableConnector = ({ t, showModal, setShowModal }) => {
  const { allProjects } = useSelector((state) => state.projectState);
  const {
    isSaving,
    autodeskAssignations,
    copyAutodeskAssignations,
    autodeskHubs,
    listCheckSelected,
    assignedAutodeskProjectSchedules,
    company,
    sortedProjects,
    showFormErrors,
    getSectorLabel,
    handleSectorSelection,
    handleHubSelection,
    handleAutodeskProjectSelection,
    handleClickCheck,
    handleSaveChangesButton,
    handleAutodeskProjectScheduleSelection,
    isLoadingListCheckSelected,
    isLoadingHubs,
    handleCloseModal,
    isShowAlert
  } = useAutodeskAssignation({ allProjects, t, setShowModal });

  const contentChildren = () => (
    <div className="procore-settings-modal">
      {isShowAlert && (
        <div className="procore-settings-modal__alert">
          <div className="alert__icon">
            <AlertIcon color={ALERT_ICON_COLOR} />
          </div>
          <span>{t('autodesk.manage_connections_modal.alert_title')}</span>
        </div>
      )}
      <div className="procore-settings-modal__title">
        <span>{t('autodesk.manage_connections_modal.title')}</span>
      </div>
      <div className="procore-settings-modal__content">
        <span className="procore-settings-modal__title__text">
          <Trans
            i18nKey="autodesk.manage_connections_modal.projects_selected"
            values={{
              connected:
                copyAutodeskAssignations?.filter(
                  (assignation) => assignation?.companyId === company?.id
                )?.length || 0,
              total: allProjects.length
            }}
          />
        </span>
        <div className="content__list-projects">
          <div className="list-projects__title">
            <div className="title__icon title__icon--project">
              <img src={OutbuildIcon} />
              <span>
                {t('autodesk.manage_connections_modal.outbuild_project')}
              </span>
            </div>
            <div className="title__icon title__icon--stage">
              <img src={OutbuildIcon} />
              <span>
                {t('autodesk.manage_connections_modal.project_stage')}
              </span>
            </div>
            <div className="title__icon title__icon--procore-company">
              <img src={AutodeskIcon} />
              <span>{t('autodesk.manage_connections_modal.autodesk_hub')}</span>
            </div>
            <div className="title__icon title__icon--procore-project">
              <img src={AutodeskIcon} />
              <span>
                {t('autodesk.manage_connections_modal.autodesk_project')}
              </span>
            </div>
            <div className="title__icon title__icon--procore-project">
              <img src={AutodeskIcon} />
              <span>
                {t('autodesk.manage_connections_modal.autodesk_schedule')}
              </span>
            </div>
          </div>
          <TableContent
            t={t}
            allProjects={allProjects}
            autodeskHubs={autodeskHubs}
            autodeskAssignations={autodeskAssignations}
            listCheckSelected={listCheckSelected}
            assignedAutodeskProjectSchedules={assignedAutodeskProjectSchedules}
            company={company}
            sortedProjects={sortedProjects}
            showFormErrors={showFormErrors}
            getSectorLabel={getSectorLabel}
            handleSectorSelection={handleSectorSelection}
            handleHubSelection={handleHubSelection}
            handleAutodeskProjectSelection={handleAutodeskProjectSelection}
            handleAutodeskProjectScheduleSelection={
              handleAutodeskProjectScheduleSelection
            }
            handleClickCheck={handleClickCheck}
            isLoadingListCheckSelected={isLoadingListCheckSelected}
            isLoadingHubs={isLoadingHubs}
          />
        </div>
      </div>
      <div className="procore-settings-modal__footer">
        <button
          disabled={isSaving}
          className="footer__cancel"
          onClick={handleCloseModal}>
          {t('procore.add_project_modal.text_button_cancel')}
        </button>
        <button
          className="footer__save"
          disabled={isSaving}
          onClick={() => handleSaveChangesButton()}>
          {isSaving ? <LoadingOutlined /> : ''}{' '}
          {t('procore.add_project_modal.text_button_save_changes')}
        </button>
      </div>
    </div>
  );

  return ModalSystem({
    visible: showModal,
    title: t('procore.add_project_modal.title'),
    theme: MODAL_THEME,
    setVisible: setShowModal,
    showIconTitle: true,
    iconTitle: <img src={AutodeskIcon} style={{ display: 'flex' }} />,
    centered: true,
    width: MODAL_WIDTH,
    children: contentChildren(),
    onCancelFunction: handleCloseModal
  });
};

AutodeskTableConnector.propTypes = {
  t: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

export default AutodeskTableConnector;
