import React from 'react';
import TooltipSystem from '../../../../DesignSystem/TooltipSystem';
import CheckSystem from '../../../../DesignSystem/CheckSystem';
import { splitText } from '../../../../../utils/splitText';
import { ArchiveIcon } from '../../../../../icons';
import { PLACEMENT_TOP, GREY_COLOR } from './constants';
import PropTypes from 'prop-types';

const OutbuildProjectCheckbox = ({
  isProjectChecked,
  project,
  projectArchived,
  t,
  handleClickCheck,
  isLoadingListCheckSelected,
  isLoadingHubs
}) => (
  <>
    <div className="detail__check">
      <CheckSystem
        disabled={isLoadingListCheckSelected || isLoadingHubs}
        checked={isProjectChecked}
        onClickFunction={(value) => handleClickCheck(value, project?.id)}
      />
    </div>
    <div className="detail__name">
      <span>{splitText(project?.name, 170)}</span>
      {projectArchived &&
        TooltipSystem({
          children: (
            <div>
              <ArchiveIcon
                color={GREY_COLOR}
                className={`${isProjectChecked ? '' : 'checked'}`}
              />
            </div>
          ),
          placement: PLACEMENT_TOP,
          title: t('procore.add_project_modal.label_procore_project_archived')
        })}
    </div>
  </>
);

OutbuildProjectCheckbox.propTypes = {
  isProjectChecked: PropTypes.bool.isRequired,
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string
  }).isRequired,
  projectArchived: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  handleClickCheck: PropTypes.func.isRequired,
  isLoadingListCheckSelected: PropTypes.bool.isRequired,
  isLoadingHubs: PropTypes.bool.isRequired
};

export default OutbuildProjectCheckbox;
