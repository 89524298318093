import React from 'react';
import PropTypes from 'prop-types';
import {
  ACTIVE,
  WHITE_COLOR,
  DISABLED,
  PLACEMENT_TOP,
  PLACEMENT_LEFT,
  ERROR,
  ERROR_COLOR,
  getTextLabel
} from './constants';
import { ArrowDownIcon } from '../../../../../icons';
import TooltipSystem from '../../../../DesignSystem/TooltipSystem';
import { Dropdown, Menu } from 'antd';
import { dynamicSort } from '../../../../../utils';

const AutodeskScheduleSelector = ({
  disableAutodeskScheduleSelector,
  isProjectChecked,
  listCheckSelected,
  project,
  showFormErrors,
  t,
  selectorAutodeskScheduleLabel,
  autodeskProjectSchedules,
  handleAutodeskProjectScheduleSelection,
  assignedAutodeskProjectSchedules
}) => (
  <div className="detail__select-project">
    <Dropdown
      overlayClassName="select-project__overlay-dropdown"
      disabled={disableAutodeskScheduleSelector || !isProjectChecked}
      overlay={
        <Menu>
          {autodeskProjectSchedules
            .sort(dynamicSort('name'))
            .map((autodeskProjectSchedule) => (
              <Menu.Item
                disabled={
                  assignedAutodeskProjectSchedules.current[
                    autodeskProjectSchedule.id
                  ]
                }
                key={autodeskProjectSchedule.id}
                className={
                  listCheckSelected.some(
                    (check) =>
                      check.proplanner_project === project?.id &&
                      check?.autodesk_schedule?.id ===
                        autodeskProjectSchedule.id
                  )
                    ? ACTIVE
                    : ''
                }
                onClick={() =>
                  handleAutodeskProjectScheduleSelection(
                    project?.id,
                    autodeskProjectSchedule
                  )
                }>
                {TooltipSystem({
                  children: TooltipSystem({
                    children: getTextLabel(
                      autodeskProjectSchedule.attributes.name
                    ),
                    placement: PLACEMENT_TOP,
                    title: autodeskProjectSchedule.name
                  }),
                  placement: PLACEMENT_LEFT,
                  title: assignedAutodeskProjectSchedules.current[
                    autodeskProjectSchedule.id
                  ]
                    ? t(
                        'autodesk.manage_connections_modal.is_already_connected'
                      )
                    : ''
                })}
              </Menu.Item>
            ))}
        </Menu>
      }
      trigger={['click']}>
      <div
        className={`select-project__dropdown ${
          disableAutodeskScheduleSelector || !isProjectChecked ? DISABLED : ''
        } ${
          showFormErrors &&
          listCheckSelected.some(
            (check) =>
              check.proplanner_project === project?.id &&
              !check.autodesk_schedule
          )
            ? ERROR
            : ''
        }`}>
        <span>
          {disableAutodeskScheduleSelector || !isProjectChecked ? (
            <div>&nbsp;</div>
          ) : (
            getTextLabel(
              selectorAutodeskScheduleLabel ||
                t('autodesk.manage_connections_modal.select_schedule')
            )
          )}
        </span>
        <ArrowDownIcon
          color={
            showFormErrors &&
            listCheckSelected.some(
              (check) =>
                check.proplanner_project === project?.id &&
                !check.autodesk_schedule
            )
              ? ERROR_COLOR
              : WHITE_COLOR
          }
        />
      </div>
    </Dropdown>
  </div>
);

AutodeskScheduleSelector.propTypes = {
  disableAutodeskScheduleSelector: PropTypes.bool.isRequired,
  isProjectChecked: PropTypes.bool.isRequired,
  listCheckSelected: PropTypes.arrayOf(
    PropTypes.shape({
      proplanner_project: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      project_stage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      autodesk_schedule: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        attributes: PropTypes.shape({
          name: PropTypes.string.isRequired
        }).isRequired
      })
    })
  ).isRequired,
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  showFormErrors: PropTypes.bool,
  t: PropTypes.func.isRequired,
  selectorAutodeskScheduleLabel: PropTypes.string,
  autodeskProjectSchedules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  handleAutodeskProjectScheduleSelection: PropTypes.func.isRequired,
  assignedAutodeskProjectSchedules: PropTypes.shape({
    current: PropTypes.object.isRequired
  }).isRequired
};

export default AutodeskScheduleSelector;
