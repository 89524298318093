import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ItemCard from '../ItemCard';
import { dynamicSort } from '../../../../utils';
import { pluginService } from '../../../../services/plugin.service';
import AutodeskIntegration from '../AutodeskIntegration/AutodeskIntegration';
import { roleTypes } from '../../../../enums/Role.enum';
import { base } from '../../../../services/base';
const AUTODESK_ALLOWED_ROLES = [roleTypes.ADMIN, roleTypes.SUPERADMIN];

const CardsGeneral = () => {
  const [isLoading, setIsLoading] = useState(false);
  const stateCompany = useSelector((state) => state.companyState);
  const idCurrentCompany = stateCompany.currentCompany?.id;
  const [allPlugins, setAllPlugins] = useState([]);
  const user = useMemo(
    () => JSON.parse(localStorage.getItem('user') || '{}'),
    []
  );

  useEffect(() => {
    const fetchPlugins = async () => {
      setIsLoading(true);
      const getPlugins = await pluginService.index();
      const sortedPlugins = getPlugins.plugins.sort(dynamicSort('name'));
      setAllPlugins(sortedPlugins);
      setIsLoading(false);
    };
    fetchPlugins();
  }, [idCurrentCompany]);

  if (isLoading) {
    return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />;
  }

  const showAutodeskIntegration =
    base.autodesk.showIntegration &&
    AUTODESK_ALLOWED_ROLES.includes(user?.role);

  return (
    <>
      {allPlugins.map((plugin) => (
        <ItemCard key={plugin.name} plugin={plugin} />
      ))}

      {showAutodeskIntegration && <AutodeskIntegration />}
    </>
  );
};

export default CardsGeneral;
