import { useState, useCallback, useMemo, useRef } from 'react';
import { autodeskIntegrationService } from '../../../../../services/autodeskintegration.service';
import cloneDeep from 'lodash/cloneDeep';
import {
  dynamicSort,
  getTypeNotification,
  notifyMessageCustom
} from '../../../../../utils';
import {
  ERROR_ICON,
  SUCCESS_ICON,
  getTextLabel
} from '../components/constants';
import * as Sentry from '@sentry/react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAutodeskHubs } from './useAutodeskHubs';

export const useAutodeskAssignation = ({ allProjects, t, setShowModal }) => {
  const [copyAutodeskAssignations, setCopyAutodeskAssignations] = useState([]);
  const [listCheckSelected, setListCheckedSelected] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const selectedCheckWithIdBackup = useRef([]);
  const assignedAutodeskProjectSchedules = useRef({});
  const [isShowAlert, setIsShowAlert] = useState(false);
  const queryClient = useQueryClient();
  const [showFormErrors, setShowFormErrors] = useState(false);

  const company = useMemo(() => {
    try {
      const companyData = sessionStorage.getItem('company');
      if (!companyData) return {};
      const parsed = JSON.parse(companyData);
      if (!parsed.id) throw new Error('Invalid company data');
      return parsed;
    } catch (error) {
      Sentry.captureMessage(
        'Invalid company data in sessionStorage',
        'warning'
      );
      return {};
    }
  }, []);
  const sortedProjects = useMemo(
    () => allProjects?.sort(dynamicSort('name')),
    [allProjects, dynamicSort]
  );

  const { autodeskHubs, isLoadingHubs } = useAutodeskHubs({ company });

  const {
    data: autodeskAssignations = [],
    isError: assignationsError,
    isLoading: isLoadingListCheckSelected
  } = useQuery(
    useMemo(
      () => ['autodeskAssignations', autodeskHubs?.length],
      [autodeskHubs?.length]
    ),
    async () => {
      const response =
        await autodeskIntegrationService.getAutodeskAssignations();
      if (!response) throw new Error('Error getting Autodesk assignations');
      return response;
    },
    {
      onSuccess: (assignations) => {
        prepareInitialListCheckSelected(assignations);
      },
      retry: false,
      enabled: !!autodeskHubs.length,
      onError: (error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'getAutodeskAssignations'
          },
          extra: { response: error.response }
        });
      }
    }
  );

  const prepareInitialListCheckSelected = (autodeskAssignations) => {
    try {
      if (autodeskAssignations && autodeskHubs) {
        setCopyAutodeskAssignations(autodeskAssignations);
        assignedAutodeskProjectSchedules.current = {};
        const listCheckSelected = autodeskAssignations.map((assignation) => {
          const {
            autodeskhubid,
            autodeskprojectid,
            scheduleAutodeskPath,
            companyId,
            projectId,
            sectorId
          } = assignation;
          const autodeskHub = autodeskHubs?.find(
            (hub) => hub.id === autodeskhubid
          );
          const autodeskProject = autodeskHub?.projects.find(
            (project) => project.id === autodeskprojectid
          );
          const autodeshProjectSchedule = autodeskProject?.schedules?.find(
            (schedule) => schedule.id === scheduleAutodeskPath
          );

          const newChecked = {
            id: assignation.id,
            autodesk_hub: autodeskHub,
            autodesk_project: autodeskProject,
            companyId,
            projectId,
            project_stage: sectorId,
            proplanner_project: projectId,
            autodesk_schedule: autodeshProjectSchedule,
            original_hub_project_schedule_id: scheduleAutodeskPath
          };
          assignedAutodeskProjectSchedules.current[scheduleAutodeskPath] = true;
          return newChecked;
        });
        setListCheckedSelected(listCheckSelected);
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'createListCheckedFromBackend'
        },
        extra: {
          response: error.response
        }
      });
    }
  };

  const { mutateAsync: deleteAutodeskAssignations } = useMutation(
    async (assignationsToDelete) =>
      await autodeskIntegrationService.deleteAllAutodeskAssignations(
        assignationsToDelete
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('autodeskAssignations');
      },
      onError: (error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'deleteAutodeskAssignations'
          },
          extra: {
            response: error.response
          }
        });
      }
    }
  );

  const handleDeleteAutodeskAssignations = async () => {
    try {
      const assignationsToDelete = [];
      copyAutodeskAssignations.forEach((assignation) => {
        const isAssigned = listCheckSelected.some(
          (check) => check.id === assignation.id
        );
        if (!isAssigned) {
          assignationsToDelete.push(assignation);
          assignedAutodeskProjectSchedules.current[
            assignation.scheduleAutodeskPath
          ] = false;
          selectedCheckWithIdBackup.current =
            selectedCheckWithIdBackup.current.filter(
              (check) => check.id !== assignation.id
            );
        }
      });

      if (assignationsToDelete.length > 0) {
        await deleteAutodeskAssignations(assignationsToDelete);
      }

      return assignationsToDelete;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'handleDeleteAutodeskAssignations'
        },
        extra: {
          response: error.response
        }
      });
      return false;
    }
  };

  const { mutateAsync: updateAutodeskAssignations } = useMutation(
    async (assignationsToUpdate) =>
      await autodeskIntegrationService.updateAllAutodeskAssignations(
        assignationsToUpdate
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('autodeskAssignations');
      },
      onError: (error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'updateAutodeskAssignations'
          },
          extra: {
            response: error.response
          }
        });
      }
    }
  );

  const handleUpdateAutodeskAssignations = async () => {
    try {
      const assignationsToUpdate = [];
      listCheckSelected.forEach((check) => {
        const validated = validateListcheckedElements(check);
        if (validated && check.id && validated.companyId === company.id) {
          assignedAutodeskProjectSchedules.current[
            check.original_hub_project_schedule_id
          ] = false;
          assignedAutodeskProjectSchedules.current[check.autodesk_schedule.id] =
            true;

          assignationsToUpdate.push({
            companyId: company.id,
            projectId: validated.proplanner_project,
            sectorId: validated.project_stage,
            autodeskhubid: validated.autodesk_hub.id,
            autodeskprojectid: validated.autodesk_project.id,
            scheduleAutodeskPath: validated.autodesk_schedule.id,
            id: check.id
          });
        }
      });

      await updateAutodeskAssignations(assignationsToUpdate);
      return true;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'handleUpdateAutodeskAssignations'
        },
        extra: {
          response: error.response
        }
      });
      return false;
    }
  };

  const handleSaveAutodeskAssignations = useCallback(async () => {
    try {
      const assignations = [];

      listCheckSelected.map((check) => {
        const validated = validateListcheckedElements(check);
        if (validated && !check.id) {
          check.companyId = company.id;
          assignations.push({
            companyId: company.id,
            projectId: validated.proplanner_project,
            sectorId: validated.project_stage,
            autodeskhubid: validated.autodesk_hub.id,
            autodeskprojectid: validated.autodesk_project.id,
            scheduleAutodeskPath: validated.autodesk_schedule.id
          });
        }
      });

      const savedAssignations =
        await autodeskIntegrationService.createAllAutodeskAssignations(
          assignations
        );

      updateListCheckSelected(savedAssignations);
      return savedAssignations;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'handleSaveAutodeskAssignations'
        },
        extra: {
          response: error.response
        }
      });
      return false;
    }
  }, [autodeskAssignations, listCheckSelected]);

  const { mutateAsync: saveChanges } = useMutation(
    async () => {
      const deleteAssignation = await handleDeleteAutodeskAssignations();
      if (!deleteAssignation) throw new Error('Error deleting assignations');

      const addAssignation = await handleSaveAutodeskAssignations();
      if (!addAssignation) throw new Error('Error saving assignations');

      const updatedAssignations = await handleUpdateAutodeskAssignations();
      if (!updatedAssignations) throw new Error('Error updating assignations');

      const updateAssignationsState = updateAutodeskAssignationsState(
        deleteAssignation,
        addAssignation
      );
      if (!updateAssignationsState)
        throw new Error('Error updating assignations state');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('autodeskAssignations');
        queryClient.invalidateQueries('autodeskHubsAndProjects');
        notifyMessageCustom({
          type: getTypeNotification(SUCCESS_ICON, 'withTitleIcon'),
          title: t(
            'procore.add_project_modal.title_success_changes_notification'
          ),
          description: t(
            'procore.add_project_modal.description_success_changes_notification'
          )
        });
      },
      onError: (error) => {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'handleSaveChangesButton'
          },
          extra: {
            response: error.response
          }
        });
        notifyMessageCustom({
          type: getTypeNotification(ERROR_ICON, 'withTitleIcon'),
          title: t(
            'procore.add_project_modal.title_error_changes_notification'
          ),
          description: t(
            'procore.add_project_modal.description_error_changes_notification'
          )
        });
      }
    }
  );

  const handleSaveChangesButton = async () => {
    try {
      setIsSaving(true);
      setShowFormErrors(false);
      const hasInvalidChecks = listCheckSelected.some(
        (check) =>
          (!check.companyId || check.companyId === company.id) &&
          !validateListcheckedElements(check)
      );

      setIsShowAlert(hasInvalidChecks);
      setShowFormErrors(hasInvalidChecks);
      if (hasInvalidChecks) {
        return;
      }

      await saveChanges();
      setShowModal(false);
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'handleSaveChangesButton'
        },
        extra: {
          response: error.response
        }
      });
    } finally {
      setIsSaving(false);
    }
  };

  const validateListcheckedElements = useCallback((checked) => {
    try {
      if (!checked) {
        return;
      }
      const {
        autodesk_hub,
        autodesk_project,
        project_stage,
        proplanner_project,
        companyId,
        autodesk_schedule
      } = checked;
      if (
        !autodesk_hub ||
        !autodesk_project ||
        !project_stage ||
        !proplanner_project ||
        !autodesk_schedule
      ) {
        return;
      }
      return {
        autodesk_hub,
        autodesk_project,
        project_stage,
        proplanner_project,
        companyId,
        autodesk_schedule
      };
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'validateListcheckedElements'
        },
        extra: {
          response: error.response
        }
      });
    }
  }, []);

  const updateAutodeskAssignationsState = (
    assignationsToDelete,
    savedAssignations
  ) => {
    try {
      setCopyAutodeskAssignations((prev) => {
        const removedAssignationsFromCopy = prev?.filter(
          (assignation) =>
            !assignationsToDelete?.some(
              (deleteAssignation) => deleteAssignation.id === assignation.id
            )
        );
        const removedAssignationsFromCopyAndAdded = [
          ...removedAssignationsFromCopy,
          ...savedAssignations
        ];

        const finalArrayWithoutDuplicates =
          removedAssignationsFromCopyAndAdded?.filter(
            (obj, index, self) =>
              index === self?.findIndex((o) => o.id === obj.id)
          );

        return finalArrayWithoutDuplicates;
      });
      return true;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'updateAutodeskAssignations'
        },
        extra: {
          response: error.response
        }
      });
      return false;
    }
  };

  const updateListCheckSelected = useCallback(
    (savedAssignations) => {
      try {
        const updatedList = listCheckSelected.map((check) => {
          const saved = savedAssignations.find(
            (saved) => saved.projectId === check.proplanner_project
          );
          if (saved) {
            check.id = saved.id;
            check.original_hub_project_schedule_id = saved.scheduleAutodeskPath;
          }
          return check;
        });
        savedAssignations.forEach((saved) => {
          autodeskAssignations.push(saved);
        });
        Object.keys(assignedAutodeskProjectSchedules.current).forEach((key) => {
          const doesExist = updatedList?.find(
            (check) => check?.autodesk_schedule?.id === key
          );
          if (doesExist) {
            assignedAutodeskProjectSchedules.current[key] = true;
          } else {
            assignedAutodeskProjectSchedules.current[key] = false;
          }
        });
        setListCheckedSelected(updatedList);
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'updateListCheckSelected'
          },
          extra: {
            response: error.response
          }
        });
      }
    },
    [listCheckSelected]
  );

  const getSectorLabel = useCallback(
    (sectors) => {
      try {
        const current = sectors.find((sector) =>
          listCheckSelected.some((check) => check.project_stage === sector.id)
        );
        if (current) return getTextLabel(current.name);

        return t('procore.add_project_modal.label_selector_stage');
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'getSectorLabel'
          },
          extra: {
            response: error.response
          }
        });
      }
    },
    [listCheckSelected]
  );

  const handleSectorSelection = (value, projectId, sectorId) => {
    try {
      setListCheckedSelected((prev) => {
        const newState = cloneDeep(prev);
        const doesExist = newState.find(
          (check) => check.proplanner_project === projectId
        );
        if (doesExist) {
          doesExist.project_stage = sectorId;
        }
        return newState;
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'handleSectorSelection'
        },
        extra: {
          response: error.response
        }
      });
    }
  };

  const handleHubSelection = (projectId, autodeskHub) => {
    try {
      setListCheckedSelected((prev) => {
        const newState = cloneDeep(prev);
        const doesExist = newState.find(
          (check) => check.proplanner_project === projectId
        );
        if (doesExist) {
          doesExist.autodesk_hub = autodeskHub;
          doesExist.autodesk_project = null;
          if (doesExist.autodesk_schedule) {
            assignedAutodeskProjectSchedules.current[
              doesExist.autodesk_schedule.id
            ] = false;
            doesExist.autodesk_schedule = null;
          }
        }
        return newState;
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'handleHubSelection'
        },
        extra: {
          response: error.response
        }
      });
    }
  };

  const handleAutodeskProjectSelection = (projectId, autodeskProject) => {
    try {
      setListCheckedSelected((prev) => {
        const newState = cloneDeep(prev);
        const doesExist = newState.find(
          (check) => check.proplanner_project === projectId
        );
        if (doesExist) {
          doesExist.autodesk_project = autodeskProject;
          if (doesExist.autodesk_schedule) {
            assignedAutodeskProjectSchedules.current[
              doesExist.autodesk_schedule.id
            ] = false;
            doesExist.autodesk_schedule = null;
          }
        }
        return newState;
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'handleAutodeskProjectSelection'
        },
        extra: {
          response: error.response
        }
      });
    }
  };

  const handleAutodeskProjectScheduleSelection = (
    projectId,
    autodeskSchedule
  ) => {
    try {
      setListCheckedSelected((prev) => {
        const newState = cloneDeep(prev);
        const doesExist = newState.find(
          (check) => check.proplanner_project === projectId
        );
        if (doesExist) {
          if (doesExist.autodesk_schedule) {
            assignedAutodeskProjectSchedules.current[
              doesExist.autodesk_schedule.id
            ] = false;
          }

          doesExist.autodesk_schedule = autodeskSchedule;
          assignedAutodeskProjectSchedules.current[autodeskSchedule.id] = true;
        }
        return newState;
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          feature: 'autodesk-integration',
          action: 'handleAutodeskProjectSelection'
        },
        extra: {
          response: error.response
        }
      });
    }
  };

  const handleClickCheck = useCallback(
    (value, projectId) => {
      try {
        setListCheckedSelected((prev) => {
          const newState = [...prev];

          if (value) {
            const backupItem = selectedCheckWithIdBackup.current.find(
              (check) => check.proplanner_project === projectId
            );

            newState.push(backupItem || { proplanner_project: projectId });
          } else {
            const filteredState = prev.filter((checked) => {
              if (checked.id) {
                const existsInBackup = selectedCheckWithIdBackup.current.some(
                  (backup) => backup.id === checked.id
                );

                if (!existsInBackup) {
                  selectedCheckWithIdBackup.current.push(checked);
                }
              }

              const uncheckedItem = prev.find(
                (check) => check.proplanner_project === projectId
              );
              if (uncheckedItem.autodesk_schedule) {
                assignedAutodeskProjectSchedules.current[
                  uncheckedItem.autodesk_schedule.id
                ] = false;
              }

              return checked.proplanner_project !== projectId;
            });

            return filteredState;
          }

          return newState;
        });
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            feature: 'autodesk-integration',
            action: 'handleClickCheck'
          },
          extra: {
            response: error.response
          }
        });
      }
    },
    [listCheckSelected]
  );

  const handleCloseModal = () => {
    setShowModal(false);
    setShowFormErrors(false);
    setIsShowAlert(false);
    prepareInitialListCheckSelected(copyAutodeskAssignations);
  };

  return {
    isSaving,
    autodeskAssignations,
    copyAutodeskAssignations,
    autodeskHubs,
    listCheckSelected,
    assignedAutodeskProjectSchedules,
    company,
    sortedProjects,
    showFormErrors,
    getSectorLabel,
    handleSectorSelection,
    handleHubSelection,
    handleAutodeskProjectSelection,
    handleClickCheck,
    handleSaveChangesButton,
    handleAutodeskProjectScheduleSelection,
    isLoadingListCheckSelected,
    isLoadingHubs,
    handleCloseModal,
    isShowAlert
  };
};
