import React from 'react';
import {
  ACTIVE,
  WHITE_COLOR,
  DISABLED,
  PLACEMENT_TOP,
  ERROR,
  ERROR_COLOR,
  getTextLabel
} from './constants';
import { ArrowDownIcon } from '../../../../../icons';
import TooltipSystem from '../../../../DesignSystem/TooltipSystem';
import { Dropdown, Menu } from 'antd';
import { dynamicSort } from '../../../../../utils';
import PropTypes from 'prop-types';

const AutodeskProjectSelector = ({
  disableAutodeskProjectSelector,
  isProjectChecked,
  listCheckSelected,
  project,
  showFormErrors,
  t,
  selectorAutodeskProjectLabel,
  autodeskProjects,
  handleAutodeskProjectSelection
}) => (
  <div className="detail__select-project">
    <Dropdown
      overlayClassName="select-project__overlay-dropdown"
      disabled={disableAutodeskProjectSelector || !isProjectChecked}
      overlay={
        <Menu>
          {autodeskProjects.sort(dynamicSort('name')).map((autodeskProject) => (
            <Menu.Item
              key={autodeskProject.id}
              className={
                listCheckSelected.some(
                  (check) =>
                    check.proplanner_project === project?.id &&
                    check?.autodesk_project?.id === autodeskProject.id
                )
                  ? ACTIVE
                  : ''
              }
              onClick={() =>
                handleAutodeskProjectSelection(project?.id, autodeskProject)
              }>
              {TooltipSystem({
                children: getTextLabel(autodeskProject.attributes.name),
                placement: PLACEMENT_TOP,
                title: autodeskProject.name
              })}
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={['click']}>
      <div
        className={`select-project__dropdown ${
          disableAutodeskProjectSelector || !isProjectChecked ? DISABLED : ''
        } ${
          showFormErrors &&
          listCheckSelected.some(
            (check) =>
              check.proplanner_project === project?.id &&
              !check.autodesk_project
          )
            ? ERROR
            : ''
        }`}>
        <span>
          {disableAutodeskProjectSelector || !isProjectChecked ? (
            <div>&nbsp;</div>
          ) : (
            getTextLabel(
              selectorAutodeskProjectLabel ||
                t('autodesk.manage_connections_modal.select_project')
            )
          )}
        </span>
        <ArrowDownIcon
          color={
            showFormErrors &&
            listCheckSelected.some(
              (check) =>
                check.proplanner_project === project?.id &&
                !check.autodesk_project
            )
              ? ERROR_COLOR
              : WHITE_COLOR
          }
        />
      </div>
    </Dropdown>
  </div>
);

AutodeskProjectSelector.propTypes = {
  disableAutodeskProjectSelector: PropTypes.bool.isRequired,
  isProjectChecked: PropTypes.bool.isRequired,
  listCheckSelected: PropTypes.arrayOf(
    PropTypes.shape({
      proplanner_project: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      project_stage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      autodesk_project: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      })
    })
  ).isRequired,
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  showFormErrors: PropTypes.bool,
  t: PropTypes.func.isRequired,
  selectorAutodeskProjectLabel: PropTypes.string,
  autodeskProjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  handleAutodeskProjectSelection: PropTypes.func.isRequired
};

export default AutodeskProjectSelector;
