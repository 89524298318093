import React from 'react';
import {
  ACTIVE,
  WHITE_COLOR,
  DISABLED,
  PLACEMENT_TOP,
  ERROR,
  ERROR_COLOR,
  getTextLabel
} from './constants';
import { ArrowDownIcon } from '../../../../../icons';
import TooltipSystem from '../../../../DesignSystem/TooltipSystem';
import { Dropdown, Menu } from 'antd';
import { dynamicSort } from '../../../../../utils';
import PropTypes from 'prop-types';

const OutbuildStageSelector = ({
  isProjectChecked,
  listCheckSelected,
  project,
  showFormErrors,
  getSectorLabel,
  handleSectorSelection
}) => (
  <div className="detail__select-project">
    <Dropdown
      overlayClassName="select-project__overlay-dropdown"
      disabled={!isProjectChecked}
      overlay={
        <Menu>
          {project?.sectors.sort(dynamicSort('name')).map((sector) => (
            <Menu.Item
              key={sector.id}
              className={
                listCheckSelected.some(
                  (check) =>
                    check.proplanner_project === sector?.projectId &&
                    check.project_stage === sector?.id
                )
                  ? ACTIVE
                  : ''
              }
              onClick={() =>
                handleSectorSelection(true, sector.projectId, sector.id)
              }>
              {TooltipSystem({
                children: getTextLabel(sector.name),
                placement: PLACEMENT_TOP,
                title: sector.name
              })}
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={['click']}>
      <div
        className={`select-project__dropdown ${
          !isProjectChecked ? DISABLED : ''
        } ${
          showFormErrors &&
          listCheckSelected.some(
            (check) =>
              check.proplanner_project === project?.id && !check.project_stage
          )
            ? ERROR
            : ''
        }`}>
        <span>
          {isProjectChecked ? (
            getSectorLabel(project?.sectors)
          ) : (
            <div>&nbsp;</div>
          )}
        </span>
        <ArrowDownIcon
          color={
            showFormErrors &&
            listCheckSelected.some(
              (check) =>
                check.proplanner_project === project?.id && !check.project_stage
            )
              ? ERROR_COLOR
              : WHITE_COLOR
          }
        />
      </div>
    </Dropdown>
  </div>
);

OutbuildStageSelector.propTypes = {
  isProjectChecked: PropTypes.bool.isRequired, // Booleano que indica si el proyecto está seleccionado
  listCheckSelected: PropTypes.arrayOf(
    PropTypes.shape({
      proplanner_project: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired, // ID del proyecto en proplanner
      project_stage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) // ID de la etapa del proyecto
    })
  ).isRequired, // Lista de elementos seleccionados
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // ID del proyecto
    sectors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired, // ID del sector
        name: PropTypes.string.isRequired, // Nombre del sector
        projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired // ID del proyecto al que pertenece el sector
      })
    ).isRequired // Lista de sectores del proyecto
  }).isRequired, // Objeto del proyecto
  showFormErrors: PropTypes.bool,
  getSectorLabel: PropTypes.func.isRequired, // Función para obtener la etiqueta del sector
  handleSectorSelection: PropTypes.func.isRequired // Función para manejar la selección de sectores
};

export default OutbuildStageSelector;
