import { LoadingOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import {
  getBasicAmplitudEventProperties,
  getSectorCompanyAndProject
} from '../../../../analytics/utils';
import Colors from '../../../../assets/styles/variables.scss';
import { roleTypes } from '../../../../enums/Role.enum';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { AddIcon, OpenModalIcon, SearchIcon } from '../../../../icons';
import { log } from '../../../../monitor/monitor';
import {
  authService,
  projectService,
  subContractService,
  userService
} from '../../../../services';
import { base } from '../../../../services/base';
import { dynamicSort } from '../../../../utils';
import { formatDateToText } from '../../../../utils/dateUtils';
import {
  getSignedUser,
  getUserStatusSettings
} from '../../../../utils/userUtils';
import ButtonSystem from '../../../DesignSystem/ButtonSystem';
import notificationSystemV2 from '../../../DesignSystem/NotificationSystemV2';
import TableSystem from '../../../DesignSystem/TableSystem';
import PermissionsMatrixModal from '../../PermissionsMatrixModal';
import Action from './Columns/Action/Action';
import Avatar from './Columns/Avatar/Avatar';
import Company from './Columns/Company/Company';
import Email from './Columns/Email/Email';
import LastActive from './Columns/LastActive/LastActive';
import Name from './Columns/Name/Name';
import Position from './Columns/Position/Position';
import Projects from './Columns/Projects/Projects';
import Role from './Columns/Role/Role';
import Status from './Columns/Status/Status';
import Surname from './Columns/Surname/Surname';
import { trackPermissionsMatrixModal } from '../../PermissionsMatrixModal/utils';

const RemoveUserModal = React.lazy(
  () => import('./RemoveUserModal/RemoveUserModal')
);

const ModalAddUsers = React.lazy(
  () => import('../../../ModalAddUsers/ModalAddUsers')
);

const ModalAddSubContract = React.lazy(
  () => import('../..//ModalAddSubContract')
);

const CardUsers = () => {
  const { t } = useTranslation();

  const { BLACK, DARK_MINT } = Colors;

  const currentUser = getSignedUser();

  const { width } = useWindowDimensions();

  const { currentCompany: sessionCompany } = getSectorCompanyAndProject();

  const { projectState } = useSelector((state) => state);

  const [dataSource, setDataSource] = useState([]);
  const [dataSourceCopy, setDataSourceCopy] = useState([]);
  const [dataSubcontracts, setDataSubcontracts] = useState([]);
  const [dataProjects, setDataProjects] = useState([]);
  const [isLoadingDataSource, setIsLoadingDataSource] = useState(true);
  const [isLoadingSubcontracts, setIsLoadingSubcontracts] = useState(true);
  const [isLoadingButtonRemove, setIsLoadingButtonRemove] = useState(false);
  const [isShowRemoveUserModal, setIsShowRemoveModal] = useState(false);
  const [isShowAddUserModal, setIsShowAddUserModal] = useState(false);
  const [isShowAddSubcontractModal, setIsShowAddSubcontractModal] =
    useState(false);
  const [currentUserIdRemove, setCurrentUserIdRemove] = useState(null);
  const [currentUserIdSubcontracts, setCurrentUserIdSubcontracts] =
    useState(null);
  const [isReloadTableUsers, setIsReloadTableUsers] = useState(false);
  const [isReloadSubcontracts, setIsReloadSubcontracts] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isNoMatchingRecords, setIsNoMatchingRecords] = useState(false);
  const [isOpenModalPermissions, setIsOpenModalPermissions] = useState(false);

  useEffect(() => {
    handleGetUsersOrganization();
    handleGetSubcontracts();
  }, [projectState.projectSelected]);

  useEffect(() => {
    if (isReloadTableUsers) {
      handleGetUsersOrganization();
      setIsReloadTableUsers(false);
      setSearchInput('');
    }
  }, [isReloadTableUsers]);

  useEffect(() => {
    if (isReloadSubcontracts) {
      handleGetSubcontracts();
      setIsReloadSubcontracts(false);
    }
  }, [isReloadSubcontracts]);

  useEffect(() => {
    setDataProjects(
      projectState.allProjects
        .filter((project) => project.stage !== 'archived')
        .sort(dynamicSort('name'))
        .map((project) => ({
          key: project.id,
          name: project.name,
          value: project.id,
          checked: false,
          icon: null,
          group: [],
          subitems: [],
          disabled: false,
          additionalIcon: null
        }))
    );
  }, []);

  const isValidRole = () =>
    currentUser.role === roleTypes.SUPERADMIN ||
    currentUser.role === roleTypes.ADMIN;

  const mappedUserToDataSource = (user) => {
    const userLastActive = user?.last_active || user?.createdAt;
    const language = t('lang') === 'es' ? 'es' : 'en';

    const userFormat = {
      ...user,
      avatar: user?.image,
      surname: user?.lastname,
      position: user?.position || '',
      subcontractId: user?.subcontract,
      status: getUserStatusSettings({
        t,
        settings: 'organization_settings',
        status: user?.status
      }),
      lastActive: formatDateToText({
        date: userLastActive,
        language
      }),
      action: user?.is_active
    };

    return userFormat;
  };

  const handleGetUsersOrganization = async () => {
    setIsLoadingDataSource(true);
    try {
      const response = await userService.getUsersOrganizationSettings();

      setDataSource(
        response?.users
          .filter((user) => user.enabling_status)
          .map((user) => mappedUserToDataSource(user))
      );

      setDataSourceCopy(
        response?.users
          .filter((user) => user.enabling_status)
          .map((user) => mappedUserToDataSource(user))
      );
    } catch (err) {
      log(
        'HANDLE_GET_USERS_ORGANIZATION_SETTINGS',
        `Error in handleGetUsersOrganization(), error: ${err}`
      );

      Sentry.captureException(err);
    } finally {
      setIsLoadingDataSource(false);
    }
  };

  const handleGetSubcontracts = async () => {
    try {
      setIsLoadingSubcontracts(true);

      if (!sessionCompany) return;

      const response = await subContractService.getByCompany(sessionCompany.id);

      setDataSubcontracts(
        response.subcontracts.sort(dynamicSort('name')).map((subcontract) => ({
          key: subcontract.id,
          name: subcontract.name,
          value: subcontract.id,
          checked: false,
          disabled: false,
          color: subcontract.color
        }))
      );
    } catch (err) {
      log(
        'HANDLE_GET_SUBCONTRACTS_ORGANIZATION_SETTINGS',
        `Error in handleGetSubcontracts(), error: ${err}`
      );

      Sentry.captureException(err);
    } finally {
      setIsLoadingSubcontracts(false);
    }
  };

  const handleUpdateUser = async (data, attribute, value) => {
    try {
      if (data[attribute] === value) return;

      let updateUser = {
        ...data,
        [attribute]: value
      };

      if (attribute === 'subcontract') {
        updateUser = {
          ...updateUser,
          subcontractId: value
        };
      }

      const response = await userService.update(updateUser);
      if (!response) {
        throw new Error('userService.update() err');
      }

      trackingUpdateUser(data, attribute, value);

      const newDataSource = [...dataSource];
      const findIndexData = newDataSource.findIndex(
        (currentData) => currentData.id === data.id
      );

      if (findIndexData < 0) return;

      newDataSource[findIndexData] = {
        ...newDataSource[findIndexData],
        [attribute]: value
      };

      setDataSource(newDataSource);

      const newDataSourceCopy = [...dataSourceCopy];
      const findIndexDataCopy = newDataSourceCopy.findIndex(
        (currentData) => currentData.id === data.id
      );

      if (findIndexDataCopy < 0) return;

      newDataSourceCopy[findIndexDataCopy] = {
        ...newDataSourceCopy[findIndexDataCopy],
        [attribute]: value
      };

      setDataSourceCopy(newDataSourceCopy);

      notificationSystemV2({
        key: `success-update-user-${attribute}-${value}`,
        type: 'success',
        message: t(
          'settings.organization_settings.users_tab.user_successfully_edited',
          {
            attribute: t(
              `settings.organization_settings.users_tab.attribute_${attribute}`
            )
          }
        )
      });
    } catch (err) {
      log(
        'HANDLE_UPDATE_USER_ORGANIZATION_SETTINGS',
        `Error in handleUpdateUser(), error: ${err}`
      );

      Sentry.captureException(err);

      notificationSystemV2({
        key: `error-update-user-${attribute}-${value}`,
        type: 'error',
        message: t(
          'settings.organization_settings.users_tab.user_failed_edited',
          {
            attribute: t(
              `settings.organization_settings.users_tab.attribute_${attribute}`
            )
          }
        )
      });
    }
  };

  const trackingUpdateUser = (data, attribute, value) => {
    const eventMapping = {
      position: {
        event: 'user_position_edition',
        newField: 'new_user_position',
        oldField: 'old_user_position'
      },
      subcontract: {
        event: 'user_company_edition',
        newField: 'new_user_company',
        oldField: 'old_user_company'
      },
      role: {
        event: 'user_role_edition',
        newField: 'new_user_role',
        oldField: 'old_user_role'
      },
      name: {
        event: 'user_name_edition',
        newField: 'new_user_name',
        oldField: 'old_user_name'
      },
      lastname: {
        event: 'user_surname_edition',
        newField: 'new_user_surname',
        oldField: 'old_user_surname'
      }
    };

    const config = eventMapping[attribute];
    if (!config) return;

    trackingEvent(
      config.event,
      {
        ...getBasicAmplitudEventProperties(),
        user_edited_id: data.id,
        user_edited_mail: data.email,
        [config.newField]: value,
        [config.oldField]: data[attribute],
        event_source: 'organization_settings_users_tab'
      },
      AMPLITUDE_SERVICE
    );
  };

  const handleOpenRemoveUserModal = (userId) => {
    setCurrentUserIdRemove(userId);
    setIsShowRemoveModal(true);
  };

  const handleItemChange = async (item, user) => {
    try {
      const data = {
        userId: user,
        projectId: item.item.value,
        isCreator: false
      };

      const response = item.addItem
        ? await projectService.asignProjectUser(data)
        : await projectService.deallocateUserOfProject(data);

      if (!response) {
        throw new Error('Error in projectService.asignProjectUser');
      }

      trackingEvent(
        'projects_assigned',
        {
          ...getBasicAmplitudEventProperties(),
          list_of_projects_assigned: item.addItem
            ? [{ id: item.item.key, name: item.item.name }]
            : [],
          list_of_projects_unassigned: item.addItem
            ? []
            : [{ id: item.item.key, name: item.item.name }],
          event_source: 'organization_settings_users_tab'
        },
        AMPLITUDE_SERVICE
      );

      notificationSystemV2({
        key: `success-update-user-${user}-${item.item.value}`,
        type: 'success',
        message: t(
          'settings.organization_settings.users_tab.user_successfully_edited',
          {
            attribute: t(
              `settings.organization_settings.users_tab.attribute_projects`
            )
          }
        )
      });
    } catch (err) {
      log(
        'HANDLE_ITEM_CHANGE_ORGANIZATION_SETTINGS',
        `Error in handleItemChange(), error: ${err}`
      );

      Sentry.captureException(err);

      notificationSystemV2({
        key: `error-item-change`,
        type: 'error',
        message: t(
          'settings.organization_settings.users_tab.user_failed_edited',
          {
            attribute: t(
              `settings.organization_settings.users_tab.attribute_projects`
            )
          }
        )
      });
    }
  };

  const handleRemoveUser = async () => {
    setIsLoadingButtonRemove(true);
    try {
      if (!currentUserIdRemove) return;

      const response = await userService.destroy(currentUserIdRemove);

      if (!response) {
        throw new Error('Error in handleRemoveUser');
      }

      trackingEvent(
        'user_removed',
        {
          ...getBasicAmplitudEventProperties(),
          user_edited_id: currentUserIdRemove,
          event_source: 'organization_settings_users_tab'
        },
        AMPLITUDE_SERVICE
      );

      const newDataSource = dataSource.filter(
        (currentUser) => currentUser.id !== currentUserIdRemove
      );

      setDataSource(newDataSource);

      const newDataSourceCopy = dataSourceCopy.filter(
        (currentUser) => currentUser.id !== currentUserIdRemove
      );

      setDataSourceCopy(newDataSourceCopy);

      notificationSystemV2({
        key: `success-remove-user-${currentUserIdRemove}`,
        type: 'success',
        message: t(
          'settings.organization_settings.users_tab.user_successfully_removed'
        )
      });
    } catch (err) {
      log(
        'HANDLE_REMOVE_USER_ORGANIZATION_SETTINGS',
        `Error in handleRemoveUser(), error: ${err}`
      );

      Sentry.captureException(err);

      notificationSystemV2({
        key: `error-remove-user-${currentUserIdRemove}`,
        type: 'error',
        message: t(
          'settings.organization_settings.users_tab.user_failed_removed'
        )
      });
    } finally {
      setCurrentUserIdRemove(null);
      setIsShowRemoveModal(false);
      setIsLoadingButtonRemove(false);
    }
  };

  const handleShowAddUserModal = () => {
    setIsShowAddUserModal(true);

    trackingEvent(
      'create_user_button_selection',
      {
        ...getBasicAmplitudEventProperties(),
        event_source: 'Organization Settings'
      },
      AMPLITUDE_SERVICE
    );
  };

  const handleReInviteUser = async (id, email) => {
    try {
      const data = {
        user: email.toLowerCase(),
        link: base.front + 'confirmation/',
        lang: t('lang')
      };

      const response = await authService.reInviteUser(data);
      if (response.status === 200) {
        trackingEvent(
          'invitation_sent',
          {
            ...getBasicAmplitudEventProperties(),
            user_edited_id: id,
            user_edited_mail: email,
            event_source: 'organization_settings_users_tab'
          },
          AMPLITUDE_SERVICE
        );

        notificationSystemV2({
          key: `success-re-invite-user-${email}`,
          type: 'success',
          message: t(
            'settings.organization_settings.users_tab.user_successfully_reinvite',
            { email: email }
          )
        });
      }
    } catch (err) {
      log(
        'HANDLE_RE_INVITE_USER_ORGANIZATION_SETTINGS',
        `Error in handleReInviteUser(), error: ${err}`
      );

      Sentry.captureException(err);

      notificationSystemV2({
        key: `error-re-invite-user-${email}`,
        type: 'error',
        message: t(
          'settings.organization_settings.users_tab.user_failed_reinvite'
        )
      });
    }
  };

  const handleSelectedSubcontract = async (data) => {
    if (!currentUserIdSubcontracts) return;

    const currentUser = dataSource.find(
      (user) => user.id === currentUserIdSubcontracts
    );

    if (!currentUser) return;

    await handleUpdateUser(currentUser, 'subcontract', data.id);
  };

  const handleChangeSearch = (event) => {
    const value = event.target.value;

    const filterDataSource = dataSourceCopy.filter(
      (data) =>
        (data.name && data.name.toLowerCase().includes(value.toLowerCase())) ||
        (data.surname &&
          data.surname.toLowerCase().includes(value.toLowerCase())) ||
        (data.email &&
          data.email.toLowerCase().includes(value.toLowerCase())) ||
        (data.position &&
          data.position.toLowerCase().includes(value.toLowerCase()))
    );

    setSearchInput(value);
    setDataSource(filterDataSource);
    setIsNoMatchingRecords(filterDataSource.length === 0);

    trackingEvent(
      'searchbox_used',
      {
        ...getBasicAmplitudEventProperties(),
        text_search: value,
        event_source: 'organization_settings_users_tab'
      },
      AMPLITUDE_SERVICE
    );
  };

  const columns = [
    {
      title: '',
      dataIndex: 'avatar',
      key: 'avatar',
      width: 44,
      render: (_, data) => <Avatar data={data} />
    },
    {
      title: t('settings.organization_settings.users_tab.table_name'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';

        return b.localeCompare(a);
      },
      render: (name, data) => (
        <Name
          name={name}
          width={width}
          onBlur={(value) => handleUpdateUser(data, 'name', value)}
          isValidRole={isValidRole()}
        />
      )
    },
    {
      title: t('settings.organization_settings.users_tab.table_surname'),
      dataIndex: 'surname',
      key: 'surname',
      width: 150,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.surname || '';
        b = b.surname || '';

        return b.localeCompare(a);
      },
      render: (surname, data) => (
        <Surname
          surname={surname}
          width={width}
          onBlur={(value) => handleUpdateUser(data, 'lastname', value)}
          isValidRole={isValidRole()}
        />
      )
    },
    {
      title: t('settings.organization_settings.users_tab.table_email'),
      dataIndex: 'email',
      key: 'email',
      width: 226,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.email || '';
        b = b.email || '';

        return b.localeCompare(a);
      },
      render: (email) => <Email email={email} width={width} />
    },
    {
      title: t('settings.organization_settings.users_tab.table_position'),
      dataIndex: 'position',
      key: 'position',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.position || '';
        b = b.position || '';

        return b.localeCompare(a);
      },
      render: (position, data) => (
        <Position
          defaultValue={position}
          onBlur={(value) => handleUpdateUser(data, 'position', value)}
          isValidRole={isValidRole()}
        />
      )
    },
    {
      title: t('settings.organization_settings.users_tab.table_company'),
      dataIndex: 'subcontract',
      key: 'subcontract',
      width: 140,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.subcontract || 0;
        b = b.subcontract || 0;

        return b - a;
      },
      render: (subcontract, data) => (
        <Company
          t={t}
          width={width}
          subcontract={dataSubcontracts.find(
            (currentSubcontract) => currentSubcontract.key === subcontract
          )}
          data={data}
          handleUpdateUser={handleUpdateUser}
          dataSubcontracts={dataSubcontracts}
          isLoadingSubcontracts={isLoadingSubcontracts}
          setIsShowAddSubcontractModal={setIsShowAddSubcontractModal}
          setCurrentUserIdSubcontracts={setCurrentUserIdSubcontracts}
          isValidRole={isValidRole()}
        />
      )
    },
    {
      title: t('settings.organization_settings.users_tab.table_role'),
      dataIndex: 'role',
      key: 'role',
      width: 165,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.role || '';
        b = b.role || '';

        return b.localeCompare(a);
      },
      render: (_, data) => (
        <Role
          t={t}
          data={data}
          currentUser={currentUser}
          handleUpdateUser={handleUpdateUser}
          isValidRole={isValidRole()}
        />
      )
    },
    {
      title: t(
        'settings.organization_settings.users_tab.table_projects_associated'
      ),
      dataIndex: 'projects',
      key: 'projects',
      width: 120,
      render: (_, data) => (
        <Projects
          t={t}
          data={data}
          isValidRole={isValidRole()}
          dataProjects={dataProjects}
          handleItemChange={handleItemChange}
        />
      )
    },
    {
      title: t('settings.organization_settings.users_tab.table_status'),
      dataIndex: 'status',
      key: 'status',
      width: 85,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        a = a.status || '';
        b = b.status || '';

        return b.localeCompare(a);
      },
      render: (status) => <Status status={status} />
    },
    {
      title: t('settings.organization_settings.users_tab.table_last_action'),
      dataIndex: 'last_active',
      key: 'last_active',
      width: 130,
      render: (_, data) => <LastActive user={data} t={t} />
    },
    {
      title: t('settings.organization_settings.users_tab.table_action'),
      dataIndex: 'action',
      key: 'action',
      width: 76,
      render: (action, data) => (
        <Action
          t={t}
          action={action}
          data={data}
          currentUser={currentUser}
          isValidRole={isValidRole()}
          handleOpenRemoveUserModal={handleOpenRemoveUserModal}
          handleReInviteUser={handleReInviteUser}
        />
      )
    }
  ];

  const handleOpenPermissionsMatrixModal = () => {
    setIsOpenModalPermissions(true);
    trackPermissionsMatrixModal();
  };

  return (
    <div className="card-users-tabs-organization">
      {isLoadingDataSource && isLoadingSubcontracts ? (
        <div className="card-users-tabs__loading">
          <LoadingOutlined />
          <span>
            {t('settings.organization_settings.users_tab.loading_data')}
          </span>
        </div>
      ) : (
        <>
          <div className="card-users-tabs__header">
            <div className="header__search">
              <input
                name="search"
                value={searchInput}
                placeholder={t(
                  'settings.organization_settings.users_tab.placeholder_search_text'
                )}
                autoComplete="off"
                onChange={handleChangeSearch}
              />
              <div className="search__icon">
                <SearchIcon color={BLACK} />
              </div>
            </div>
            <div className="header__button">
              <div
                className="button__matrix"
                onClick={() => handleOpenPermissionsMatrixModal()}>
                <div>
                  <OpenModalIcon color={DARK_MINT} />
                </div>
                <span>
                  {t(
                    'settings.organization_settings.users_tab.show_user_matrix_button'
                  )}
                </span>
              </div>
              <ButtonSystem
                icon={<AddIcon color={BLACK} />}
                onClick={() => handleShowAddUserModal()}
                disabled={!isValidRole()}>
                {t(
                  'settings.organization_settings.users_tab.button_add_users_text'
                )}
              </ButtonSystem>
            </div>
          </div>
          <div className="card-users-tabs__table">
            <TableSystem
              loading={isLoadingDataSource}
              columns={columns}
              dataSource={dataSource}
              pagination={{ pageSize: 50 }}
              rowKey={(row) => row.id}
              isNoMatchingRecords={isNoMatchingRecords}
              scroll={{ x: 'auto' }}
            />
          </div>
          <Suspense
            fallback={
              <div>
                {t('settings.organization_settings.users_tab.suspense_loading')}
              </div>
            }>
            <RemoveUserModal
              t={t}
              show={isShowRemoveUserModal}
              setShow={setIsShowRemoveModal}
              handleRemoveUser={handleRemoveUser}
              setCurrentUserIdRemove={setCurrentUserIdRemove}
              isLoadingButtonRemove={isLoadingButtonRemove}
            />
          </Suspense>
          <Suspense
            fallback={
              <div>
                {t('settings.organization_settings.users_tab.suspense_loading')}
              </div>
            }>
            <ModalAddUsers
              isShow={isShowAddUserModal}
              setIsShow={setIsShowAddUserModal}
              setReloadTableUsers={setIsReloadTableUsers}
              setDataSubcontractsExternal={setIsReloadSubcontracts}
            />
          </Suspense>
          <Suspense
            fallback={
              <div>
                {t('settings.organization_settings.users_tab.suspense_loading')}
              </div>
            }>
            <ModalAddSubContract
              t={t}
              Visible={isShowAddSubcontractModal}
              setVisible={setIsShowAddSubcontractModal}
              setReloadTableUsers={setIsReloadSubcontracts}
              newSubcontractFunction={(resp) => handleSelectedSubcontract(resp)}
            />
          </Suspense>
          <PermissionsMatrixModal
            handleVisibility={setIsOpenModalPermissions}
            isOpen={isOpenModalPermissions}
          />
        </>
      )}
    </div>
  );
};

export default CardUsers;
