import React, { useState, useMemo, useEffect } from 'react';
import { Card, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Animated } from 'react-animated-css';
import ButtonSystem from '../../../DesignSystem/ButtonSystem';
import AutodeskImage from '../../../../assets/img/autodesk/autodesk-circle.png';
import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { useAutodeskIntegration } from './hooks/useAutodeskIntegration';
import AutodeskTableConnector from './components/AutodeskTableConnector';
import AutodeskNotConnectedModal from './components/AutodeskNotConnectedModal';
import { useAutodeskHubs } from './hooks/useAutodeskHubs';
import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { Colors } from '../../../../constants/colors.constants';
import * as Sentry from '@sentry/browser';

const AutodeskIntegration = () => {
  const { t } = useTranslation();
  const { accessToken, handleLogin, isLoading } = useAutodeskIntegration();
  const [showModal, setShowModal] = useState(false);
  const [showNotConnectedModal, setShowNotConnectedModal] = useState(false);
  const company = useMemo(() => {
    try {
      const companyData = sessionStorage.getItem('company');
      if (!companyData) return {};
      const parsed = JSON.parse(companyData);
      if (!parsed.id) throw new Error('Invalid company data');
      return parsed;
    } catch (error) {
      Sentry.captureMessage(
        'Invalid company data in sessionStorage',
        'warning'
      );
      return {};
    }
  }, []);
  const { hasHubsAccess, isFirstHubsLoading } = useAutodeskHubs({
    company,
    accessToken,
    retry: showNotConnectedModal
  });

  useEffect(() => {
    if (accessToken?.showNotConnectedModal) {
      setShowNotConnectedModal(true);
    }
  }, [accessToken]);

  const renderCardContent = () => {
    if (isLoading || isFirstHubsLoading) {
      return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />;
    }

    if (!accessToken) {
      return (
        <ButtonSystem onClick={handleLogin}>
          {t('autodesk.connect')}
        </ButtonSystem>
      );
    }

    if (!hasHubsAccess) {
      return (
        <ButtonSystem
          onClick={() => {
            trackingEvent(
              'manage_autodesk_integration',
              {
                organization_id: company?.id,
                organization_name: company?.name
              },
              AMPLITUDE_SERVICE
            );

            setShowNotConnectedModal(true);
          }}>
          {t('autodesk.complete_connection')}
        </ButtonSystem>
      );
    }

    return (
      <>
        <ButtonSystem onClick={() => setShowModal(true)}>
          {t('autodesk.manage_connections')}
        </ButtonSystem>
        <div className="settings-plugins__card-connected">
          <CheckCircleTwoTone twoToneColor={Colors.LIGHT_MINT} />
          {t('autodesk.connected')}
        </div>
      </>
    );
  };

  return (
    <Animated animationIn="fadeIn" animationInDuration={1000}>
      <Card
        className="settings-plugins__card"
        cover={
          <img className="settings-plugins__card-cover" src={AutodeskImage} />
        }
        title={t('autodesk.title')}>
        {renderCardContent()}
      </Card>

      {!hasHubsAccess && (
        <AutodeskNotConnectedModal
          t={t}
          showModal={showNotConnectedModal}
          setShowModal={setShowNotConnectedModal}
        />
      )}

      {accessToken && hasHubsAccess && (
        <AutodeskTableConnector
          t={t}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </Animated>
  );
};

export default AutodeskIntegration;
