import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Colors from '../../../assets/styles/variables.scss';
import {
  CheckCircleIcon,
  DenyCircleIcon,
  OneCircleIcon,
  ReadOnlyIcon,
  ThreeCircleIcon,
  TwoCircleIcon,
  UserSquareSingleIcon
} from '../../../icons';
import ModalSystem from '../../DesignSystem/ModalSystem';
import TableSystem from '../../DesignSystem/TableSystem';
import './index.scss';
import { columns, permissionsData } from './utils';

function PermissionsMatrixModal({ handleVisibility, isOpen }) {
  const { t } = useTranslation();
  const { PRIMARY, YELLOW, LIGHT_RED, LIGHT_BLUE } = Colors;

  return (
    <ModalSystem
      width={1100}
      visible={isOpen}
      setVisible={handleVisibility}
      theme="dark"
      showTitle={true}
      title={t('permissions_info_title')}
      showIconTitle={true}
      iconTitle={<UserSquareSingleIcon color={PRIMARY} />}
      onCancelFunction={() => handleVisibility(false)}>
      <div className="tableWrapper">
        <TableSystem
          theme="dark"
          loading={false}
          rowKey={(row) => row.id}
          columns={columns(t)}
          dataSource={permissionsData(t)}
          pagination={false}
        />
        <div className="permissions-legend">
          <div>
            <div className="permission-item">
              <div>
                <CheckCircleIcon color={PRIMARY} />
                <p>{t('permissions_matrix.legends.full_access')}</p>
              </div>
              <div>
                <ReadOnlyIcon color={LIGHT_BLUE} />
                <p>{t('permissions_matrix.legends.read_only')}</p>
              </div>
              <div>
                <DenyCircleIcon color={LIGHT_RED} />
                <p>{t('permissions_matrix.legends.no_access')}</p>
              </div>
            </div>
            <div className="permission-item">
              <div>
                <OneCircleIcon color={YELLOW} />
                <p>
                  {t(
                    'permissions_matrix.legends.user_can_see_everything_edit_tasks'
                  )}
                </p>
              </div>
              <div>
                <TwoCircleIcon color={YELLOW} />
                <p>{t('permissions_matrix.legends.user_can_see_roadblocks')}</p>
              </div>
              <div>
                <ThreeCircleIcon color={YELLOW} />
                <p>
                  {t(
                    'permissions_matrix.legends.user_can_see_everything_edit_progress'
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalSystem>
  );
}
PermissionsMatrixModal.propTypes = {
  handleVisibility: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default PermissionsMatrixModal;
